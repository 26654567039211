<template>
  <Overview
        :moduleName="$tc(navName, 1)"
        :moduleNamePlural="$tc(navName, 2)"
        sort="full_name"
        :newButtonName="isCustomer ? false : $t('form.add', [$t(navName)])"
        :selectFields=selectFields
        :filterOptions="filterOptions"
        :itemService=itemService
        :itemFormRef=itemFormRef
        ref="overview"
    >
        <template #columns>
            <Column field="full_name" :header="$t('overview.name')" :sortable="true">
              <template #body="slotProps">
                <div class="uploaded-logo uploaded-logo-sm" :style="{'background-image': `url(${slotProps.data.icon_url || (slotProps.data.franchise ? slotProps.data.franchise.icon_url : false)})`}"></div>
                {{slotProps.data.full_name}}
              </template>
           </Column>
            <Column field="city" :header="$t('location.place')" :sortable="true" style="width: 160px"></Column>
            <Column field="debtor_number" :header="$t('location.debtornr')" :sortable="true" style="width: 150px"></Column>
            <Column field="dealer.name" :header="$t('navigation.dealers')" :sortable="true" style="width: 200px"></Column>
            <Column field="source_setting.version_number" :header="$t('overview.version')" :sortable="true" style="width: 100px">
              <template #body="slotProps">
                <span v-if="slotProps.data.source_setting.version_number" data-bs-toggle="tooltip" :data-bs-title="setVersionNumber(slotProps.data.source_setting.version_number)">{{slotProps.data.source_setting.version_number.split(" ")[0]}}</span>
              </template>
            </Column>
            <Column field="source_autosync" :header="$t('location.sync')" :sortable="false" style="width: 80px">
              <template #body="slotProps">
                  <div class="d-flex gap-2 text-success">
                    <i class="far fa-file-import" v-show="slotProps.data.source_autosync" :data-bs-toggle="slotProps.data.source_autosync ? 'tooltip' : ''" :data-bs-title="`Automatisch importeren`"></i>
                    <i class="far fa-file-export" v-show="slotProps.data.bookkeeping_autosync" :data-bs-toggle="slotProps.data.bookkeeping_autosync ? 'tooltip' : ''" :data-bs-title="`Automatisch exporteren`"></i>
                    <i class="far fa-check-to-slot" v-show="slotProps.data.check_daystates" :data-bs-toggle="slotProps.data.check_daystates ? 'tooltip' : ''" :data-bs-title="`Dagstaten controleren`"></i>
                  </div>
              </template>
            </Column>
            <Column field="name" :header="$t('overview.links')" :sortable="false">
              <template #body="slotProps">
                <div class="d-flex gap-2 align-items-center">
                  <div class="pill text-light"
                    :class="getStatus(slotProps.data.source_setting)"
                    :data-bs-toggle="slotProps.data.source_setting.status_message ? `tooltip` : null" :data-bs-title="slotProps.data.source_setting.status_message"
                    >
                      unTill
                  </div>
                  <div v-if="slotProps.data.bookkeeping_system_location && slotProps.data.bookkeeping_system_location.bookkeeping_system_auth" 
                    class="pill text-light" :class="getStatus(slotProps.data.bookkeeping_system_location)" :data-bs-toggle="getBTooltip(slotProps.data)" :data-bs-title="getExpiredMessage(slotProps.data)"
                    >
                      {{slotProps.data.bookkeeping_system_location.bookkeeping_system_auth.bookkeeping_system.name}}
                  </div>
                  <div v-if="slotProps.data.reservation_system_location" class="pill bg-unidark text-light" :class="getStatus(slotProps.data.reservation_system_location)"
                      :data-bs-toggle="getResTooltip(slotProps.data)" :data-bs-title="getResStatusMessage(slotProps.data)"
                    >
                      {{slotProps.data.reservation_system_location.reservation_system.name}} <i class="far fa-info-circle ms-1" v-show="slotProps.data.reservation_system_location.external_sync"></i>
                  </div>
                </div>
              </template>
            </Column>
        </template>
    </Overview>
</template>

<script>
import Overview from '@/components/Overview.vue';
import Column from 'primevue/column';
import bookService from '@/services/BookkeepingService'
import csService from '@/services/CustomerService'
import dealService from '@/services/DealerService'
import franService from '@/services/FranchiseService'
import resService from '@/services/ReservationSystemService'
import datastore from '@/store/data'
import store from '@/store/user'

export default {
    components: {
        Overview,
        Column
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.$refs.overview.getIndex(from);
        })
    },
    data() {
        return {
            item: {},
            itemService: csService,
            itemFormRef: null,
            companyTypes: [],
            selectFields: [],
            bookkeepingsystems: [],
            dealers: [],
            ressystems: [],
            isCustomer: true,
            modulesLoaded: 0,
            savedFilters: null,
            navName: 'navigation.locations',
            franService: franService,
            filterOptions: []
        }
    },
    methods: {
        setVersionNumber(number){
          let dateMatch = number.match(/\d{4}-\d{2}-\d{2}/);
          if(dateMatch && dateMatch[0]){
            let splittedDate = dateMatch[0].split('-');
            let newDate = splittedDate[2] + '-' + splittedDate[1] + '-' + splittedDate[0];
            return number.replace(dateMatch[0], newDate);
          }
          return number;
        },
        getStatus(data){
          const statuses = {"active": "bg-unidark", "error": "bg-danger", "pending": "bg-secondary", "inactive": "bg-secondary"};
          return statuses[data.status] || 'bg-unidark';
        },
        getBTooltip(data){
          return data.bookkeeping_system_location.bookkeeping_system_auth.expired ? 'tooltip' : null;
        },
        getResTooltip(data){
          return data.reservation_system_location.status_message || data.reservation_system_location.external_sync ? 'tooltip' : null;
        },
        getExpiredMessage(data){
          return data.bookkeeping_system_location.bookkeeping_system_auth.expired ? this.$t('overview.expired_notice') : '';
        },
        getResStatusMessage(data){
          return data.reservation_system_location.status_message || (data.reservation_system_location.external_sync ? this.$t('overview.external_sync_notice') : '');
        },
        setFilters(){
          if(this.modulesLoaded == 3){
              this.filterOptions = [
                {
                    field: 'franchise.id',
                    service: this.franService,
                    name: this.$t('navigation.franchises'),
                    multiple: true
                },
                {
                    field: 'dealer.id',
                    options: this.dealers,
                    name: this.$t('navigation.dealers')
                },
                {
                    field: 'bookkeepingSystemLocation.bookkeepingSystemAuth.bookkeeping_system_id',
                    options: this.bookkeepingsystems,
                    name: this.$t('locationtabs.step_5')
                },
                {
                    field: 'reservationSystemLocation.reservationSystem.id',
                    options: this.ressystems,
                    name: this.$t('locationtabs.step_6')
                },
                {
                    field: 'source_autosync',
                    boolean: 1,
                    name: `Automatisch importeren`
                },
                {
                    field: 'bookkeeping_autosync',
                    boolean: 1,
                    name: `Automatisch exporteren`
                },
                {
                    field: 'check_daystates',
                    boolean: 1,
                    name: `Dagstaten controleren`
                }
              ];
              this.$nextTick(() => {
                this.$refs.overview.$refs.overviewfilters.setFilters();
                if(this.savedFilters){
                  this.$refs.overview.$refs.overviewfilters.filterData = this.savedFilters;
                  this.$refs.overview.$refs.overviewfilters.save();
                }
            });
          }
        }
    },
    mounted() {
      //Not customers allowed if they don't have access to at least two locations
      if(datastore.getters.getLocationsToShow != 'all'){
        this.$router.push({name: 'Home'});
      }
      this.isCustomer = store.getters.getUserType == 2;
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');
      const state = urlParams.get('state');

      // const sessionState = urlParams.get('session_state');
      if (code) {
        this.handleCallback(code, state, window.location.pathname)
      }
      dealService.index().then(response => {
        this.dealers = response.data;
        this.modulesLoaded++;
        this.setFilters();
      });
      bookService.index().then(response => {
        this.bookkeepingsystems = response.data;
        this.bookkeepingsystems.sort((a, b) => a.name.localeCompare(b.name));
        this.modulesLoaded++;
        this.setFilters();
      });
      resService.index().then(response => {
        this.ressystems = response.data;
        this.ressystems.sort((a, b) => a.name.localeCompare(b.name));
        this.modulesLoaded++;
        this.setFilters();
      });
    }
}
</script>
